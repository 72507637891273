/* eslint-disable eqeqeq */
import React, { useState } from "react";
import axios from "axios";
import "../css/resumo.css";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import {
  Box,
  Button,
  CircularProgress,
  createMuiTheme,
  Modal,
  Typography,
} from "@material-ui/core";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import { format } from "date-fns";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },

  btnNormal: {
    display: "inline-block",
    width: 200,
    height: 180,
    borderRadius: "10px",
    margin: "10px",
  },
  btnActive: {
    display: "inline-block",
    width: 200,
    height: 180,
    boxShadow: "0px 4px 8px 10px #ebebeb",
    borderRadius: "10px",
    margin: "10px",
  },
  btnNormalSmall: {
    display: "inline-block",
    width: 150,
    height: 130,
    borderRadius: "10px",
    margin: "10px",
  },
  btnActiveSmall: {
    display: "inline-block",
    width: 150,
    height: 130,
    boxShadow: "0px 4px 8px 10px #ebebeb",
    borderRadius: "10px",
    margin: "10px",
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: { main: "#05b6b7" },
  },
  // props: {
  //   MuiButton: {
  //     disableElevation: true,
  //   },
  // },
  overrides: {
    MuiButton: {
      root: {
        "&.Mui-disabled": {
          boxShadow:
            "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12) !important",
        },
      },
    },
  },
});

function getSteps() {
  return ["Dados Pessoais", "Localização"];
}

function useQuery() {
  return new URLSearchParams(window.location.search);
}

const PaymentForm = ({ resumo, preferenciaContacto, cicloEnergetico }) => {
  console.log(resumo);
  return (
    <div style={{ height: "100%" }}>
      <div className="titulo">
        Envie-nos os seus dados para o podermos ajudar!
      </div>
      <div className="card">
        <h3>Dados Pessoais</h3>
        <br />
        <div className="dados">
          <h5>Nome:</h5> <span> {resumo.nome}</span>
          <br />
          <br />
          <h5>Apelido:</h5> <span>{resumo.apelido}</span>
          <br />
          <br />
          <h5>Telemóvel:</h5> <span>{resumo.telemovel}</span>
          <br />
          <br />
          <h5>Email:</h5> <span>{resumo.email}</span>
          <br />
          <br />
          <h5>Nº de Cartão de Cidadão/BI:</h5>{" "}
          <span>{resumo.numero_documento}</span>
          <br />
          <br />
          <h5>Validade do documento:</h5>{" "}
          <span>
            {format(new Date(resumo.validade_documento), "dd/MM/yyyy")}
          </span>
          <br />
          <br />
          <h5>NIF:</h5> <span>{resumo.nif}</span>
          <br />
          <br />
          <h5>Beneficio de Tarifa social:</h5>{" "}
          <span>{resumo.tarifa_social == false ? "Não" : "Sim"}</span>
          <br />
          <br />
          <h5>O Titular é o mesmo do contrato anterior:</h5>{" "}
          <span>
            {resumo.titular_contrato_anterior == false ? "Não" : "Sim"}
          </span>
          <br />
          <br />
          <h5>Preferência de contacto:</h5> {preferenciaContacto()}
          <br />
          <br />
          {resumo.certidao_permanente && (
            <div>
              <h5>Certidão Permanente:</h5>
              <span>{resumo.certidao_permanente}</span>
            </div>
          )}
        </div>
      </div>
      <br />
      <div className="card">
        <h3>Localização</h3>
        <br />
        <div className="dados">
          <h5>Morada:</h5> <span>{resumo.morada}</span>
          <br />
          <br />
          <h5>CUI:</h5> <span>{resumo.cui}</span>
          <br />
          <br />
          <h5>Código Postal:</h5> <span>{resumo.cod_postal}</span>
          <br />
          <br />
          <h5>Localidade:</h5> <span>{resumo.localidade}</span>
          <br />
          <br />
          <h5>Fornecimento de gás:</h5>{" "}
          <span>{resumo.fornecimento_gas == false ? "Não" : "Sim"}</span>
          <br />
          <br />
          <h5>Inspeção de gás:</h5>{" "}
          <span>{resumo.inspecao_gas == false ? "Não" : "Sim"}</span>
        </div>
      </div>
      <br />
      <div className="card">
        <h3>Energias</h3>
        <br />
        <div className="dados">
          <h5>Fonte(s) energética(s):</h5>
          {resumo.isElectricidade === true && (
            <div>
              Eletricidade <br /> <br />
              <h5>Tipo de tarifa:</h5>
              <span>{resumo.tarifa === "simples" && "Simples"}</span>
              <span>{resumo.tarifa === "bi_horario" && "Bi Horário"}</span>
              <span>{resumo.tarifa === "tri_horario" && "Tri horário"}</span>
              <br />
              <br />
              <h5>Potência:</h5> <span>{resumo.potencia}</span>
              <br />
              <br />
            </div>
          )}
          {resumo.is_electricidade_gas === true && (
            <div>
              Eletricidade e gás
              <br />
              <br />
              <h5>Tipo de tarifa:</h5>
              <span>{resumo.tarifa}</span>
              <br />
              <br />
              <h5>Potência:</h5> <span>{resumo.potencia}</span>
              <br />
              <br />
              <h5>Escalão:</h5>
              <span>{resumo.escalao === "escalao1" && "Escalão 1"}</span>
              <span>{resumo.escalao === "escalao2" && "Escalão 2"}</span>
              <span>{resumo.escalao === "escalao3" && "Escalão 3"}</span>
              <span>{resumo.escalao === "escalao4" && "Escalão 4"}</span>
            </div>
          )}
          <span>{resumo.isGas === true && "Gás"}</span>
          {resumo.isGas === true && (
            <div>
              <br />
              <h5>Escalão:</h5>
              <span>{resumo.escalao === "escalao1" && "Escalão 1"}</span>
              <span>{resumo.escalao === "escalao2" && "Escalão 2"}</span>
              <span>{resumo.escalao === "escalao3" && "Escalão 3"}</span>
              <span>{resumo.escalao === "escalao4" && "Escalão 4"}</span>
              <br />
            </div>
          )}
          {resumo.isMaisEnergia === true && (
            <div>
              Mais Energia <br /> <br />
              <h5>Tipo de tarifa:</h5>
              <span>{resumo.tarifa === "simples" && "Simples"}</span>
              <span>{resumo.tarifa === "bi_horario" && "Bi Horário"}</span>
              <span>{resumo.tarifa === "tri_horario" && "Tri Horário"}</span>
              <br />
              <br />
              <h5>Potência:</h5> <span>{resumo.potencia}</span>
              <br />
              <br />
            </div>
          )}
          {resumo.ciclo && (
            <div>
              <h5>Ciclo:</h5>
              <span>{cicloEnergetico()}</span>

              <br />
              <br />
            </div>
          )}
        </div>
      </div>
      <br />
      {resumo?.faturaGas?.file?.name ||
      resumo?.comprovativoIban?.file?.name ||
      resumo?.outrosDocumentos?.file?.name ||
      resumo?.observacoes ? (
        <>
          <div className="card">
            <h3>Documentos</h3>
            <br />
            <div className="dados">
              {resumo?.faturaGas?.file?.name ? (
                <>
                  <h5>Fatura de Gás:</h5>{" "}
                  <span>{resumo?.faturaGas?.file?.name}</span>
                  <br />
                  <br />
                </>
              ) : null}
              {resumo?.comprovativoIban?.file?.name ? (
                <>
                  <h5>Comprovativo de IBAN:</h5>{" "}
                  <span>{resumo?.comprovativoIban?.file?.name}</span>
                  <br />
                  <br />
                </>
              ) : null}
              {resumo?.outrosDocumentos?.file?.name ? (
                <>
                  <h5>Outros Documentos:</h5>{" "}
                  <span>{resumo?.outrosDocumentos?.file?.name}</span>
                  <br />
                  <br />
                </>
              ) : null}
              {resumo?.observacoes ? (
                <>
                  <h5>Observações:</h5> <span> {resumo.observacoes}</span>
                  <br />
                  <br />
                </>
              ) : null}
            </div>
          </div>
        </>
      ) : null}

      <br />
    </div>
  );
};

const TarifaPotenciaTermos = ({ resumo, step }) => {
  return (
    <div style={{ height: "100%" }}>
      <div className="titulo">Formulário enviado com sucesso!</div>
      <div className="final">
        <p>Obrigado por preferir os nossos serviços! </p>
        Os seus dados foram enviados, irá ser contactado pela equipa comercial
        através do email indicado:
        <br />
        <b>{resumo.email}</b>
      </div>
    </div>
  );
};

function getStepContent(
  step,
  methods,
  resumo,
  preferenciaContacto,
  cicloEnergetico
) {
  switch (step) {
    case 0:
      return (
        <PaymentForm
          methods={methods}
          resumo={resumo}
          preferenciaContacto={preferenciaContacto}
          cicloEnergetico={cicloEnergetico}
        />
      );

    case 1:
      return (
        <TarifaPotenciaTermos methods={methods} resumo={resumo} step={step} />
      );

    default:
      return "unknown step";
  }
}

const Resumo = ({
  value,
  setValue,
  methods,
  resumo,
  resetFormDados,
  resetFormLocalizacao,
  resetFormEnergia,
  resetFormDocumentos,
  setDisabledSteps,
}) => {
  const classes = useStyles();
  const query = useQuery();

  // => Modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [activeStep, setActiveStep] = useState(0);

  const steps = getSteps();

  const [loading, setLoading] = useState(false);

  const preferenciaContacto = () => {
    if (resumo.preferencia_contacto === "email") {
      return "Email";
    } else if (resumo.preferencia_contacto === "contacto_telefonico") {
      return "Contacto Telefónico";
    } else if (resumo.preferencia_contacto === "correio_postal") {
      return "Correio Postal";
    }
  };

  const cicloEnergetico = (tipoTarifa) => {
    console.log(tipoTarifa);
    if (tipoTarifa == 1 && resumo.ciclo === "semCiclo") {
      return 1;
    } else if (tipoTarifa == 2 && resumo.ciclo === "diario") {
      return 2;
    } else if (tipoTarifa == 2 && resumo.ciclo === "semanal") {
      return 3;
    } else if (tipoTarifa == 3 && resumo.ciclo === "diario") {
      return 4;
    } else if (tipoTarifa == 3 && resumo.ciclo === "semanal") {
      return 5;
    } else if (tipoTarifa == 4 && resumo.ciclo === "semCiclo") {
      return 6;
    } else if (tipoTarifa == 5 && resumo.ciclo === "diario") {
      return 7;
    } else if (tipoTarifa == 5 && resumo.ciclo === "semanal") {
      return 8;
    }
  };

  const tipoEscalao = () => {
    if (resumo.escalao === "escalao1" && resumo.tarifa_social === true) {
      return 5;
    } else if (resumo.escalao === "escalao2" && resumo.tarifa_social === true) {
      return 6;
    } else if (resumo.escalao === "escalao3") {
      return 3;
    } else if (resumo.escalao === "escalao4") {
      return 4;
    } else if (resumo.escalao === "escalao1") {
      return 1;
    } else if (resumo.escalao === "escalao2") {
      return 2;
    }
  };

  const tipoTarifa = () => {
    if (resumo.tarifa === "simples" && resumo.isMaisEnergia === true) {
      return 1;
    } else if (
      resumo.tarifa === "bi_horario" &&
      resumo.isMaisEnergia === true
    ) {
      return 2;
    } else if (
      resumo.tarifa === "tri_horario" &&
      resumo.isMaisEnergia === true
    ) {
      return 3;
    } else if (
      resumo.tarifa === "simples" &&
      resumo.isMaisEnergia === true &&
      resumo.tarifa_social === true
    ) {
      return 4;
    } else if (
      resumo.tarifa === "bi_horario" &&
      resumo.isMaisEnergia === true &&
      resumo.tarifa_social === true
    ) {
      return 5;
    }
  };

  const tarifPlan = () => {
    if (resumo.is_electricidade_gas === true) {
      return 4;
    } else if (resumo.isGas === true && resumo.tarifa_social === true) {
      return 7;
    } else if (resumo.isGas === true) {
      return 5;
    } else if (resumo.isMaisEnergia === true && resumo.tarifa_social === true) {
      return 6;
    } else if (resumo.isMaisEnergia === true) {
      return 3;
    }
  };

  const renderPotencia = (potencia, tipotarifa) => {
    switch (tipotarifa) {
      case 1:
        switch (potencia) {
          case "1.15 kVA":
            return 1;
          case "2.3 kVA":
            return 2;
          case "3.45 kVA":
            return 3;
          case "4.6 kVA":
            return 4;
          case "5.75 kVA":
            return 5;
          case "6.9 kVA":
            return 6;
          case "10.35 kVA":
            return 7;
          case "13.8 kVA":
            return 8;
          case "17.25 kVA":
            return 9;
          case "20.7 kVA":
            return 10;
          default:
            break;
        }
        break;
      case 2:
        switch (potencia) {
          case "1.15 kVA":
            return 11;
          case "2.3 kVA":
            return 12;
          case "3.45 kVA":
            return 13;
          case "4.6 kVA":
            return 14;
          case "5.75 kVA":
            return 15;
          case "6.9 kVA":
            return 16;
          case "10.35 kVA":
            return 17;
          case "13.8 kVA":
            return 18;
          case "17.25 kVA":
            return 19;
          case "20.7 kVA":
            return 20;
          default:
            break;
        }
        break;
      case 3:
        switch (potencia) {
          case "27.6 kVA":
            return 21;
          case "34.5 kVA":
            return 22;
          case "41.4 kVA":
            return 23;
          default:
            break;
        }
        break;
      case 4:
        switch (potencia) {
          case "1.15 kVA":
            return 24;
          case "2.3 kVA":
            return 25;
          case "3.45 kVA":
            return 26;
          case "4.6 kVA":
            return 27;
          case "5.75 kVA":
            return 28;
          case "6.9 kVA":
            return 29;
          default:
            break;
        }
        break;
      case 5:
        switch (potencia) {
          case "1.15 kVA":
            return 30;
          case "2.3 kVA":
            return 31;
          case "3.45 kVA":
            return 32;
          case "4.6 kVA":
            return 33;
          case "5.75 kVA":
            return 34;
          case "6.9 kVA":
            return 35;
          default:
            break;
        }
        break;
      default:
        break;
    }
  };

  const handleNext = () => {
    setLoading(true);

    const formatedDate = format(
      new Date(resumo.validade_documento),
      "yyyy-MM-dd"
    );

    let contrato = query.get("contrato") || "eletricidade";

    if (contrato !== "eletricidade" && contrato !== "gas") {
      contrato = "eletricidade";
    }

    const tipoTarifaValue = tipoTarifa();

    const dados = {
      contrato: contrato,
      // Dados pessoais
      nome: resumo.nome,
      apelido: resumo.apelido,
      telemovel: resumo.telemovel,
      email: resumo.email,
      nif: resumo.nif,
      numero_documento: resumo.numero_documento,
      validade_documento: formatedDate,
      certidao_permanente: resumo.certidao_permanente,
      tarifa_social: resumo.tarifa_social,
      titular_contrato_anterior: resumo.titular_contrato_anterior,
      // preferencia_contacto: preferenciaContacto(),
      email_flag: resumo.preferencia_contacto == "email" ? 1 : 0,
      phone_flag: resumo.preferencia_contacto == "contacto_telefonico" ? 1 : 0,
      mail_flag: resumo.preferencia_contacto == "correio_postal" ? 1 : 0,
      // Localização
      morada: resumo.morada,
      cpe: resumo.cpe,
      cui: resumo.cui,
      localidade: resumo.localidade,
      cod_postal: resumo.cod_postal,
      nova_ligacao: resumo.nova_ligacao,
      fornecimento_energia: resumo.fornecimento_energia,
      fornecimento_gas: resumo.fornecimento_gas == "yes" ? true : false,
      inspecao_gas: resumo.inspecao_gas == "yes" ? true : false,
      // Energias
      // is_electricidade_gas: resumo.is_electricidade_gas,
      // isElectricidade: resumo.isElectricidade,
      // isGas: resumo.isGas,
      // isMaisEnergia: resumo.isMaisEnergia,
      plan: tarifPlan(),
      tipotarifa: tipoTarifaValue,
      potencia: renderPotencia(resumo.potencia, tipoTarifaValue),
      ciclo: cicloEnergetico(tipoTarifaValue),
      escalao: tipoEscalao(),
      aceito_condicoes_gerais: resumo.aceito_condicoes_gerais,
      //Documentos
      observacoes: resumo.observacoes,
    };

    const comprovativoIban = resumo.comprovativoIban.file;
    const faturaGas = resumo.faturaGas.file;
    const outrosDocumentos = resumo.outrosDocumentos.file;

    const formdata = new FormData();
    formdata.append("dados", JSON.stringify(dados));
    formdata.append("faturaGas", faturaGas);
    formdata.append("comprovativoIban", comprovativoIban);
    formdata.append("outrosDocumentos", outrosDocumentos);

    console.log([...formdata]);

    // if (validationResumo()) {
    // setDisabledSteps(true);
    // setActiveStep(1);
    console.log(dados);
    axios
      .post("https://jafplus.api.adesao.jaf.com.pt/api", formdata)
      .then((data) => {
        console.log(data);
        handleOpen();
        setLoading(false);
        // setActiveStep(activeStep + 1);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error occurred during POST request:", error);
        // Handle the error here, such as showing an error message to the user
      });
    // .then(() => setDisabledSteps(true));
    // .catch((error) => {
    //   if (error.response) {
    //     // Server responded with a status code outside the 2xx range
    //     console.error("Error response:", error.response.data);
    //     console.error("Error status:", error.response.status);
    //     console.error("Error headers:", error.response.headers);
    //   } else if (error.request) {
    //     // Request was made but no response received
    //     console.error("Error request:", error.request);
    //   } else {
    //     // Something happened in setting up the request
    //     console.error("Error message:", error.message);
    //   }
    //   console.error("Error config:", error.config);
    // });
    // setActiveStep(activeStep + 1);
  };
  const novaSimulação = (e) => {
    e.preventDefault();
    setActiveStep(activeStep + 1);
    resetFormDados();
    resetFormEnergia();
    resetFormLocalizacao();
    resetFormDocumentos();
    setValue(0);
    setDisabledSteps(false);
  };

  const handleBack = () => {
    if (activeStep <= 0) {
      setValue(value - 1);
    }
    setActiveStep(activeStep - 1);
  };

  return (
    <div>
      <ThemeProvider theme={defaultMaterialTheme}>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ color: "#05b6b7" }}
            >
              Formulário enviado com sucesso!
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Obrigado por preferir os nossos serviços!
              <br />
              Os seus dados foram enviados. Irá ser contactado pela nossa equipa
              comercial através do seu e-mail:
              <b style={{ fontSize: "1rem", marginLeft: 2 }}>
                {methods.getValues("email")}
              </b>
            </Typography>
            <Box sx={{ display: "flex", mt: 2 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={novaSimulação}
                sx={{
                  my: 2,
                  mr: 1,
                  backgroundColor: "#05b6b7",
                  borderRadius: "25px",
                  color: "white",
                }}
                size="large"
              >
                Nova Adesão
              </Button>
              <a href="https://jafplus.pt/particulares/">
                <Button
                  color="primary"
                  type="submit"
                  sx={{
                    my: 2,
                    borderRadius: "25px",
                    border: "2px solid #05b6b7",
                    color: "black",
                  }}
                  size="large"
                >
                  Voltar a Jaf Plus
                </Button>
              </a>
            </Box>
          </Box>
        </Modal>
        {activeStep === steps.length ? (
          setValue(0)
        ) : (
          <>
            <form onSubmit={methods.handleSubmit(handleNext)}>
              {" "}
              {activeStep === 0 && (
                <Button
                  className={classes.button}
                  onClick={handleBack}
                  style={{
                    paddingRight: 10,

                    borderRadius: "25px",
                  }}
                >
                  <ChevronLeft />
                  <span
                    style={{
                      paddingRight: 0,
                      paddingLeft: 2,
                      display: "flex",
                      marginTop: "1px",
                    }}
                  >
                    voltar
                  </span>
                </Button>
              )}
              <br />
              <br />
              {getStepContent(
                activeStep,
                methods,
                resumo,
                preferenciaContacto,
                cicloEnergetico
              )}
              <br />
              {activeStep === 0 ? (
                <>
                  {!loading ? (
                    <Button
                      className={classes.button}
                      variant="contained"
                      color="primary"
                      type="submit"
                      style={{
                        paddingRight: 10,
                        backgroundColor: "#05b6b7",
                        borderRadius: "25px",
                        color: "white",
                      }}
                      size="large"
                    >
                      Aderir
                      <span
                        style={{
                          paddingRight: 0,
                          paddingLeft: 2,
                          display: "flex",
                        }}
                      >
                        <ChevronRight />
                      </span>
                    </Button>
                  ) : (
                    <>
                      <button onClick={() => setLoading(true)}></button>
                      <Button
                        className={classes.buttonLoading}
                        variant="contained"
                        color="primary"
                        type="submit"
                        style={{
                          paddingRight: 10,
                          // backgroundColor: '#d7d7d7',
                          borderRadius: "25px",
                          color: "white",
                          boxShadow: "5px 10px #888888 !important",
                        }}
                        size="large"
                        disabled
                        elevation={8}
                      >
                        <CircularProgress size="1.2rem" />
                        <p style={{ marginLeft: "10px", marginRight: "10px" }}>
                          Aderir
                        </p>

                        <span
                          style={{
                            paddingRight: 0,
                            paddingLeft: 2,
                            display: "flex",
                          }}
                        >
                          {/* <ChevronRight /> */}
                        </span>
                      </Button>
                    </>
                  )}
                </>
              ) : (
                <>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={novaSimulação}
                    style={{
                      backgroundColor: "#05b6b7",
                      borderRadius: "25px",
                      color: "white",
                    }}
                    size="large"
                  >
                    Nova simulação
                    <span
                      style={{
                        display: "flex",
                      }}
                    ></span>
                  </Button>
                  <a
                    href="https://jafplus.pt/particulares/"
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    <Button
                      className={classes.button}
                      variant="contained"
                      color="primary"
                      style={{
                        backgroundColor: "transparent",

                        borderRadius: "25px",
                        border: "2.5px solid #05b6b7",
                        color: "black",
                        textDecoration: "none",
                      }}
                      size="large"
                    >
                      Voltar a JAFplus
                      <span
                        style={{
                          display: "flex",
                        }}
                      ></span>
                    </Button>
                  </a>
                </>
              )}
            </form>
          </>
        )}
      </ThemeProvider>
    </div>
  );
};

export default Resumo;
